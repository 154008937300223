<script setup lang="ts">
import { useRoute } from 'vue-router/composables'
import { getAuthServiceConfig } from '@/auth-service'
import { computed, getCurrentInstance } from 'vue'

defineProps<{
  type: 'login' | 'register'
}>()

const emit = defineEmits(['complete'])

const { endpoint, token, setToken, type: tokenType } = getAuthServiceConfig()
const returnUrl = window.location.href

const route = useRoute()

if (route.query.token) {
  setToken(route.query.token as string, 'auth')
  emit('complete')
}

const app = getCurrentInstance()!;

const helpText = computed(() => app.proxy.$configurations.authServiceHelpText)

const prospectToken = computed(() => tokenType === 'auth-prospect' ? token : undefined)
</script>

<template>
  <div class="auth-service-login">
    <template v-if="type === 'login'">
      <p>
        We have recently updated how you access the customer portal. Please select 'Register your account' and follow the steps to get yourself registered
      </p>
      
      <ignite-auth-service-register-button
        :endpoint="endpoint"
        :return-url="returnUrl"
      />

      <p class="double-top-margin">
        If you have a registered account, then please sign in with one of the options below
      </p>
    </template>

    <ignite-auth-service-login-buttons
      :endpoint="endpoint"
      :return-url="returnUrl"
      :prospect-token="prospectToken"
      :type="type"
    />

    <ignite-auth-service-forgot-button
      v-if="type === 'login'"
      :endpoint="endpoint"
      :return-url="returnUrl"
    />

    <p v-if="helpText">{{ helpText }}</p>
  </div>
</template>

<style lang="css" scoped>
.auth-service-login {
  margin: auto;
}

.auth-service-login {
  text-align: center;
  row-gap: 12px;
  display: flex;
  flex-direction: column;
  align-items: center
}

.auth-service-new p {
  font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", Segoe UI Symbol, "Noto Color Emoji";
}

.double-top-margin {
  margin-top: 32px;
}
</style>
