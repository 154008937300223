import type Vue from 'vue'
import type { Route } from 'vue-router'
import type IDocsPreference from './IDocsPreference'
import type IFeatures from './IFeatures'
import type INotification from './INotification'
import type ITrustPilot from './ITrustPilot'
import type IRenewalDiscount from './IRenewalDiscount'
import type { SummaryPageTooltip } from './config-objects/SummaryPageTooltip'
import type ProductEnum from '@/services/enums/ProductEnum'
import type PaymentTypeEnum from '@/services/enums/PaymentTypeEnum'
import type Step from '@/view-models/Step'
import type PolicySummary from '@/services/values/PolicySummary'
import type Policy from '@/services/models/Policy'
import type Routes from '@/constants/Routes'
import type IExperiments from '@/configurations/IExperiments'
import type { IBenefitsDashboardItemSpecification, IDirectDebitPopUpSpecification, IIgnitePaymentGateway, IPaymentIcons, IPaymentMessagesSpecification } from '@/configurations/config-objects/ConfigSpecification'
import type IDirectDebitProvidersCancellationText from '@/services/interfaces/direct-debit/IDirectDebitProvidersCancellationText'
import type ClaimSummary from '@/services/values/ClaimSummary'
import type { TenantConfigName } from '@/services/types/TenantConfigName'

export class LinkConfig {
  name: string
  isDisabled?: boolean

  click: (vue: Vue) => void

  constructor(
    name: string,
    click: (vue: Vue) => void,
    isDisabled: boolean = false,

  ) {
    this.name = name
    this.click = click
    this.isDisabled = isDisabled
  }
}

interface IConfiguration {
  aggregatorEditRoute: Routes
  alternatePremiumTitleText: string | null
  brochureSite: string
  claimsAndConvictions: boolean
  viewClaimsLinks: (policy: PolicySummary, claim: ClaimSummary) => LinkConfig[]
  alternateChangeDirectDebitPaymentDate: (policy: Policy) => LinkConfig[]
  collapseDocsEndorsementsTables: boolean
  collapseDocsEndorsementsTablesAtDesktop: boolean
  cookiePolicyNotice: string | null
  dashboardClaimsLinks: LinkConfig[]
  defaultPaymentType: PaymentTypeEnum
  directDebitProvidersCancellationText: IDirectDebitProvidersCancellationText
  cancellationHasClaimsText: string
  docsPreference: (product: ProductEnum) => IDocsPreference
  experiments: IExperiments
  features: IFeatures
  footer: string
  footerLinks: LinkConfig[]
  footerLogoPath: string
  goToFirstPageOnPremiumPreviousClick: boolean
  headerText: string
  hideBillingAddress: () => boolean
  hideCoverLevelDetailFeatures: boolean
  hidePaymentCoverSummary: boolean
  showPaymentSummary: boolean
  hidePaymentPagePolicySummary: boolean
  ignitePaymentGateway: IIgnitePaymentGateway | null
  blockMtaIfInArrears: boolean
  aggregatorLandingForProduct: ((product: ProductEnum) => string | null) & ((product: ProductEnum) => string | null)
  actionsForPolicy: ((policy: PolicySummary | Policy) => LinkConfig[]) & ((policy: PolicySummary | Policy) => LinkConfig[])
  claimsLinks: ((policy: PolicySummary) => LinkConfig[]) & ((policy: PolicySummary) => LinkConfig[])
  navLinkForProduct: (product: ProductEnum) => string | null
  companyNameForProduct: (product: ProductEnum) => string | null
  footerTextForProduct: (product: ProductEnum) => string | null
  footerAlignment: string
  logoUrlForProduct: (product: ProductEnum) => string | null
  headerLogoPathForProduct: (product: ProductEnum) => string | null
  footerLogoPathForProduct: (product: ProductEnum) => string | null
  footerLinksForProduct: (product: ProductEnum) => LinkConfig[] | null
  errorMessageForCode: ((code: number) => string) & ((code: number) => string)
  directDebitPopUp: ((product: ProductEnum) => IDirectDebitPopUpSpecification) & ((product: ProductEnum) => IDirectDebitPopUpSpecification)
  documentsDeliveryPreferenceModal: { enabled: boolean, text: string }
  mostPopularVap: { enabled: boolean, paymentType: string }
  mtaRenewalDeclineMessage: string
  navLinkForAdditionalProduct: (product: ProductEnum) => string | null
  nbDeclineMessage: string
  notification: INotification
  notificationBannerText: string
  paymentMessages: IPaymentMessagesSpecification
  paymentIcons: IPaymentIcons
  paymentPageNotice: string | null
  paymentSummaryAdditionalProducts: boolean
  directDebitInformation: (schemeName: string) => string
  paymentRenewalInformation: string
  policyTakeupNextAddress: string
  rebrokeText: string
  referralText: string
  renewalDiscount: IRenewalDiscount | null
  renewalEntryPageIndex: number
  renewalOptOutMarkdown: string | null
  showCantAfford: boolean
  showCoverLevelDetailOnSchemeCard: boolean
  showPolicyDetailsOnSchemeCard: boolean
  showDocumentsPopup: boolean
  showRemoveAll: boolean
  skipAggregatorLandingPage: boolean
  socialLinks: LinkConfig[]
  specialButton: LinkConfig | null
  stepsForProduct: ((product: ProductEnum, route: Route) => Step[]) & ((product: ProductEnum, route: Route) => Step[])
  tenantConfigName: TenantConfigName
  tenantFriendlyName: string
  trustPilot: ITrustPilot | null
  veriskEnabled: boolean
  abiDumpCodeFormat: string
  headerLogoPath: string
  logoUrl: string
  installAppText: string
  loginHelpText: string
  mtaVapSelectionInformation: string
  goCardlessRenewalText: string
  premiumPageHideNext: boolean
  newBusinessPremiumPageNextClickUpdatesQuote: boolean
  showVapIpt: boolean
  showPremiumOnVapPage: boolean
  accountHeaderActions: LinkConfig[]
  summaryPageTooltips: SummaryPageTooltip[]
  benefitsDashboardItem: IBenefitsDashboardItemSpecification
  alternateAPMTAPremiumHeader: string
  alternateAddNewProductMarkup: string
  authServiceHelpText: string
}

export default IConfiguration
