import type { Route } from 'vue-router'
import type { LinkConfig } from './IConfiguration'
import type IConfiguration from './IConfiguration'
import type IExperiments from './IExperiments'
import type IDocsPreference from './IDocsPreference'
import type ProductEnum from '@/services/enums/ProductEnum'
import Step from '@/view-models/Step'
import type PolicySummary from '@/services/values/PolicySummary'
import type Policy from '@/services/models/Policy'
import LinkConfigFactory from '@/configurations/LinkConfigFactory'
import type { ILinkConfigSpecification } from '@/configurations/config-objects/LinkConfigSpecification'
import { LinkConfigActionShowTypes } from '@/configurations/config-objects/LinkConfigSpecification'
import type {
  IAggregatorConfigSpecification,
  IDirectDebitPopUpSpecification,
  IGeneralConfigSpecification,
  IJourneyConfigSpecification,
} from '@/configurations/config-objects/ConfigSpecification'
import type Routes from '@/constants/Routes'
import type PaymentTypeEnum from '@/services/enums/PaymentTypeEnum'
import { PWAUtils } from '@/plugins/pwa-utils'
import RenewalStatusEnum from '@/services/enums/RenewalStatusEnum'
import type ClaimSummary from '@/services/values/ClaimSummary'
import type { TenantConfigName } from '@/services/types/TenantConfigName'

const linkFilterFunction = (l: ILinkConfigSpecification) => l.showOn.length === 0 || (PWAUtils.isMobile() && !!l.showOn.find(s => s === LinkConfigActionShowTypes.mobile)) || (!PWAUtils.isMobile() && !!l.showOn.find(s => s === LinkConfigActionShowTypes.desktop))

export default class ConfigurationLoader {
  static createConfiguration(general: IGeneralConfigSpecification, agg: IAggregatorConfigSpecification, journey: IJourneyConfigSpecification, experiments: IExperiments): IConfiguration {
    return {
      directDebitProvidersCancellationText: general.directDebitProvidersCancellationText,
      cancellationHasClaimsText: general.cancellationHasClaimsText,
      tenantConfigName: window.injectedEnv.FRONTEND_TENANT as TenantConfigName,
      tenantFriendlyName: window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME,
      footer: general.footer,
      dashboardClaimsLinks: general.dashboardClaimsLinks.filter(linkFilterFunction).map((l: ILinkConfigSpecification) => LinkConfigFactory.create(l)),
      rebrokeText: general.rebrokeText,
      headerText: general.headerText,
      socialLinks: general.socialLinks.filter(linkFilterFunction).map((l: ILinkConfigSpecification) => LinkConfigFactory.create(l)),
      headerLogoPath: general.headerLogoPath,
      logoUrl: general.logoUrl,
      footerLogoPath: general.footerLogoPath,
      renewalEntryPageIndex: journey.renewalEntryPageIndex,
      aggregatorEditRoute: agg.aggregatorEditRoute as Routes,
      footerLinks: general.footerLinks.filter(linkFilterFunction).map((l: ILinkConfigSpecification) => LinkConfigFactory.create(l)),
      policyTakeupNextAddress: journey.policyTakeupNextAddress,
      specialButton: journey.specialButton.length > 0 && (linkFilterFunction(journey.specialButton[0])) ? LinkConfigFactory.create(journey.specialButton[0]!) : null,
      alternateChangeDirectDebitPaymentDate(policy: Policy) {
        const specs = general.alternateChangeDirectDebitPaymentDate.filter(l => (!l.showForScheme) || (l.showForScheme && l.showForScheme.toLowerCase() === policy.scheme.name.toLowerCase()))
          .filter(linkFilterFunction).map((l: ILinkConfigSpecification) => LinkConfigFactory.create(l, policy))
        return specs
      },
      features: general.features,
      notification: general.notification,
      defaultPaymentType: journey.defaultPaymentType as PaymentTypeEnum,
      notificationBannerText: general.notificationBannerText,
      renewalOptOutMarkdown: journey.renewalOptOutMarkdown,
      skipAggregatorLandingPage: agg.skipAggregatorLandingPage,
      trustPilot: general.trustPilot,
      accountHeaderActions: general.accountHeaderActions.filter(linkFilterFunction).map((l: ILinkConfigSpecification) => LinkConfigFactory.create(l)),
      referralText: general.referralText,
      cookiePolicyNotice: general.cookiePolicyNotice,
      showCantAfford: general.showCantAfford,
      abiDumpCodeFormat: general.abiDumpCodeFormat,
      aggregatorLandingForProduct(product: ProductEnum): string | null {
        return agg.aggregatorLandingForProduct[product]
      },
      paymentSummaryAdditionalProducts: journey.paymentSummaryAdditionalProducts,
      navLinkForAdditionalProduct(product: ProductEnum): string | null {
        return journey.navLinkForAdditionalProduct ? journey.navLinkForAdditionalProduct[product] : journey.navLinkForProduct[product]
      },
      docsPreference(product: ProductEnum): IDocsPreference {
        return general.docsPreference[product] ?? general.docsPreference.default
      },
      navLinkForProduct(product: ProductEnum): string | null {
        return journey.navLinkForProduct[product]
      },
      companyNameForProduct(product: ProductEnum): string | null {
        return journey.companyNameForProduct[product]
      },
      footerTextForProduct(product: ProductEnum): string | null {
        return journey.footerTextForProduct[product]
      },
      logoUrlForProduct(product: ProductEnum): string | null {
        return journey.logoUrlForProduct[product]
      },
      headerLogoPathForProduct(product: ProductEnum): string | null {
        return journey.headerLogoPathForProduct[product]
      },
      footerLogoPathForProduct(product: ProductEnum): string | null {
        return journey.footerLogoPathForProduct[product]
      },
      footerLinksForProduct(product: ProductEnum): LinkConfig[] | null {
        return journey.footerLinksForProduct![product].filter(linkFilterFunction).map((l: ILinkConfigSpecification) => LinkConfigFactory.create(l))
      },
      stepsForProduct(product: ProductEnum, route: Route): Step[] {
        const productSteps = journey.stepsForProduct[product]
        return productSteps.find(s => !s.matches || s.matches.find(m => m.toLowerCase() === route.name!.toLowerCase() || route.matched.find(rm => rm.name === m.toLowerCase())) || s.matches.length < 1)?.steps.map(s => new Step(s.label, s.icon, s.routes.map(r => r as Routes), s.pack)) ?? []
      },
      actionsForPolicy(policy: PolicySummary | Policy): LinkConfig[] {
        const specs = general.actionsForPolicy[policy.product].filter(z => (!z.linkConfig!.showOnlyForRenewalStatus) || (z.linkConfig!.showOnlyForRenewalStatus && z.linkConfig!.showOnlyForRenewalStatus.includes(policy.renewalStatus ?? RenewalStatusEnum.notSet)))
          .filter(pa => policy.availableActions.some(aa => !pa.matches || pa.matches.some(m => m.toLowerCase() === aa.toLowerCase())))
          .filter(a => !a.linkConfig || linkFilterFunction(a.linkConfig)).map(a => LinkConfigFactory.create(a.linkConfig!, policy))
        return specs
      },
      claimsLinks(policy: PolicySummary): LinkConfig[] {
        const specs = general.claimsLinks[policy.product].filter(l => (!l.showForVap && !l.showForScheme) || (l.showForScheme && l.showForScheme.toLowerCase() === policy.schemeName?.toLowerCase()) || (l.showForVap && policy.vaps.some(v => v.toLowerCase() === l.showForVap?.toLowerCase())))
          .filter(linkFilterFunction).map((l: ILinkConfigSpecification) => LinkConfigFactory.create(l, policy))
        return specs
      },
      viewClaimsLinks(policy: PolicySummary, claim: ClaimSummary): LinkConfig[] {
        return general.viewClaimsLinks.filter(linkFilterFunction).map((l: ILinkConfigSpecification) => LinkConfigFactory.create(l, policy, claim))
      },
      errorMessageForCode(code: number): string {
        return general.errorMessageForCode[code.toString()]
      },
      directDebitPopUp(product: ProductEnum): IDirectDebitPopUpSpecification {
        return journey.directDebitPopUp[product]
      },
      documentsDeliveryPreferenceModal: journey.documentsDeliveryPreferenceModal,
      footerAlignment: general.footerAlignment,
      paymentMessages: journey.paymentMessages,
      paymentIcons: journey.paymentIcons,
      paymentRenewalInformation: journey.paymentRenewalInformation,
      directDebitInformation(schemeName: string): string {
        const ddMarkdown = journey.directDebitInformation.find(l => l.EnabledForSchemeName && l.EnabledForSchemeName.toLowerCase() === schemeName?.toLowerCase())?.MarkdownText
        if (ddMarkdown)
          return ddMarkdown

        return journey.directDebitInformation.find(l => !l.EnabledForSchemeName)?.MarkdownText ?? ''
      },
      installAppText: general.installAppText,
      collapseDocsEndorsementsTables: journey.collapseDocsEndorsementsTables,
      collapseDocsEndorsementsTablesAtDesktop: journey.collapseDocsEndorsementsTablesAtDesktop,
      experiments,
      hideBillingAddress(): boolean {
        if (general.ignitePaymentGateway && general.ignitePaymentGateway.enabled)

          return general.ignitePaymentGateway.presentationMethod === 2

        return journey.hideBillingAddress
      },
      hidePaymentCoverSummary: journey.hidePaymentCoverSummary,
      showPaymentSummary: journey.showPaymentSummary,
      hidePaymentPagePolicySummary: journey.hidePaymentPagePolicySummary,
      showRemoveAll: journey.showRemoveAll,
      nbDeclineMessage: journey.nbDeclineMessage,
      mostPopularVap: journey.mostPopularVap,
      mtaRenewalDeclineMessage: journey.mtaRenewalDeclineMessage,
      veriskEnabled: general.veriskEnabled,
      ignitePaymentGateway: general.ignitePaymentGateway,
      blockMtaIfInArrears: general.blockMtaIfInArrears,
      goToFirstPageOnPremiumPreviousClick: journey.goToFirstPageOnPremiumPreviousClick,
      brochureSite: journey.brochureSite,
      renewalDiscount: general.renewalDiscount,
      claimsAndConvictions: journey.claimsAndConvictions,
      paymentPageNotice: journey.paymentPageNotice,
      showCoverLevelDetailOnSchemeCard: journey.showCoverLevelDetailOnSchemeCard,
      showPolicyDetailsOnSchemeCard: journey.showPolicyDetailsOnSchemeCard,
      showDocumentsPopup: journey.showDocumentsPopup,
      alternatePremiumTitleText: journey.alternatePremiumTitleText,
      hideCoverLevelDetailFeatures: journey.hideCoverLevelDetailFeatures,
      loginHelpText: general.loginHelpText,
      mtaVapSelectionInformation: journey.mtaVapSelectionInformation,
      goCardlessRenewalText: general.goCardlessRenewalText,
      premiumPageHideNext: general.premiumPageHideNext,
      newBusinessPremiumPageNextClickUpdatesQuote: journey.newBusinessPremiumPageNextClickUpdatesQuote,
      showVapIpt: journey.showVapIpt,
      showPremiumOnVapPage: journey.showPremiumOnVapPage,
      summaryPageTooltips: journey.summaryPageTooltips,
      benefitsDashboardItem: general.benefitsDashboardItem,
      alternateAPMTAPremiumHeader: journey.alternateAPMTAPremiumHeader,
      alternateAddNewProductMarkup: general.alternateAddNewProductMarkup,
      authServiceHelpText: general.authServiceHelpText,
    }
  }
}
